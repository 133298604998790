import React, {
  CSSProperties,
  forwardRef,
  createContext,
  createRef,
  useEffect,
} from 'react';
import { VariableSizeGrid as VGrid } from 'react-window';
import TableSkeleton from '../TableSkeleton';
import NoResults from '../NoResults';
import AutoSizer from 'react-virtualized-auto-sizer';
import { PeepRow } from '../../services/mapper';
import { makeStyles, Theme } from '@material-ui/core';
import {
  COLUMN_WIDTH,
  COLUMNS,
  COL_HEADER_WIDTH,
  HEADER_HEIGHT,
} from './GridDefaults';
import TopRow from './WeekHeader';
import TodayHighlight from './TodayHighlight';
import { blueGrey } from '@material-ui/core/colors';

const initial = {
  data: [] as PeepRow[],
  width: 0,
  ItemRenderer: React.Children,
  StickyRow: (() => null) as React.FC,
  StickyColumn: (() => null) as React.FC<{ data: any; width: number }>,
};

const StickyGridContext = createContext({ ...initial });

StickyGridContext.displayName = 'StickyGridContext';

const ItemWrapper = ({ data, ...rest }) => {
  const { ItemRenderer, data: itemData } = data;
  return <ItemRenderer data={itemData} {...rest} />;
};

const innerElementType = forwardRef<HTMLDivElement, { style: CSSProperties }>(
  ({ children, style }, ref) => {
    return (
      <StickyGridContext.Consumer>
        {({ data, width, StickyColumn, StickyRow }) => {
          return (
            <div ref={ref} style={style}>
              <StickyRow />
              <StickyColumn data={data} width={width} />
              <TodayHighlight isFixed height={style.height} />
              {children}
            </div>
          );
        }}
      </StickyGridContext.Consumer>
    );
  }
);

const StickyGrid = ({
  children,
  data,
  StickyColumn,
  StickyRow,
  width,
  ...rest
}) => {
  const variableSizeGridRef = createRef<VGrid>();
  useEffect(() => {
    if (variableSizeGridRef.current) {
      variableSizeGridRef.current.resetAfterRowIndex(0);
    }
  }, [data, variableSizeGridRef]);
  return (
    <StickyGridContext.Provider
      value={{
        width,
        ItemRenderer: children,
        data,
        StickyColumn,
        StickyRow,
      }}
    >
      <VGrid
        ref={variableSizeGridRef}
        width={width}
        itemData={{ ItemRenderer: children, data }}
        {...rest}
        useIsScrolling
      >
        {ItemWrapper}
      </VGrid>
    </StickyGridContext.Provider>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    height: 50,
  },
  relative: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  cell: {
    width: 300,
  },
  bordered: {
    border: `dotted ${blueGrey[200]}`,
    borderLeftWidth: '1px',
    borderBottomWidth: '0',
    borderTopWidth: '1px',
    borderRightWidth: '0',
  },
  sticky: {
    position: 'sticky',
    zIndex: 7,
    backgroundColor: '#fff',
  },
  wrapper: {
    flex: 1,
    background: theme.palette.background.default,
  },
  deadCell: {
    width: COL_HEADER_WIDTH,
    height: HEADER_HEIGHT,
    position: 'absolute',
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${blueGrey[200]}`,
    boxShadow: '2px 0px 2px hsla(0,0%,0%,0.1)',
    zIndex: 20,
  },
}));

export const WrappedGrid = ({
  data,
  lastUpdate,
  rowHeight,
  Cell,
  StickyColumn,
  StickyRow = TopRow,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.deadCell}></div>
      <AutoSizer>
        {({ width, height }) => (
          <StickyGrid
            key={lastUpdate}
            height={height}
            columnCount={COLUMNS}
            columnWidth={() => COLUMN_WIDTH}
            innerElementType={innerElementType}
            rowCount={data.length ?? 0}
            rowHeight={rowHeight}
            width={width}
            itemSize={35}
            data={data ?? []}
            StickyColumn={StickyColumn}
            StickyRow={StickyRow}
          >
            {Cell}
          </StickyGrid>
        )}
      </AutoSizer>
    </div>
  );
};

const AutosizedVirtualGrid = props => {
  if (!props.data || props.isLoadingData) {
    return <TableSkeleton />;
  } else if (props.data.length === 0) {
    return <NoResults />;
  } else {
    return <WrappedGrid {...props} />;
  }
};

export default AutosizedVirtualGrid;
