import React, { memo } from 'react';
import { useResources } from '../Resources';
import BookingViewCell from './BookingViewCell';
import AutosizedVirtualGrid from '../Grid/AutosizedVirtualGrid';
import { PeepRow } from '../../services/mapper';
import UserHeader from '../Grid/UserHeader';
import { ROW_HEIGHT, ROW_PADDING } from '../Grid/GridDefaults';

const mapSideHeader = (peep: PeepRow, index) => {
  const multiplier =
    peep.overlappingBookings === 0 ? 1 : peep.overlappingBookings;
  const height = multiplier * ROW_HEIGHT + ROW_PADDING * 2;
  return (
    <UserHeader key={index} style={{ left: 0, width: 200, height }} {...peep} />
  );
};

const StickyBookingsColumn: React.FC<{ data: PeepRow[] }> = ({ data }) => {
  return <>{data.map(mapSideHeader)}</>;
};

const FullView = () => {
  const { lastUpdate, filteredPeepRows, isLoadingData } = useResources();

  const CellMemo = memo(
    BookingViewCell,
    (oldProps, { columnIndex, rowIndex, isScrolling }) =>
      oldProps.columnIndex === columnIndex &&
      oldProps.rowIndex === rowIndex &&
      isScrolling
  );

  const rowHeight = r => {
    const overlapping = filteredPeepRows
      ? filteredPeepRows[r].overlappingBookings
      : 1;

    return overlapping === 0
      ? ROW_HEIGHT + ROW_PADDING * 2
      : overlapping * ROW_HEIGHT + ROW_PADDING * 2;
  };

  return (
    <AutosizedVirtualGrid
      data={filteredPeepRows}
      isLoadingData={isLoadingData}
      lastUpdate={lastUpdate}
      rowHeight={rowHeight}
      Cell={CellMemo}
      StickyColumn={StickyBookingsColumn}
    />
  );
};

export default FullView;
