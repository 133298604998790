import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  makeStyles,
  createStyles,
  Paper,
  Grid,
  Theme,
} from '@material-ui/core';
import { useResources } from './Resources';
import ButtonGroupSelect from './ButtonGroupSelect';
import {
  ViewMode,
  FilterDispatchType,
  BookingType,
} from '../hooks/useFilterReducer';
import { Peep, isBooking } from '../services/models';
import { NO_PROJECTS } from '../services/projectService';
import AutocompleteMulti from './AutocompleteMulti';
import { UNASSIGNED_ROLE } from '../services/mapper';
import ClearAllButton from './ClearAllButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterContainer: {
      background: theme.palette.background.default,
      right: '1rem',
      top: '3rem',
      display: 'block',
      position: 'fixed',
      width: '20rem',
      padding: '0.3rem 1.3rem',
      zIndex: 1000,
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 3rem)',
    },
    gridItem: {
      width: '100%',
    },
    gridContainer: {
      height: '100%',
      position: 'relative',
    },
    clearAll: {
      width: '100%',
      marginTop: '1rem', // sets itself apart a bit
      marginBottom: '0.5rem',
    },
    filters: {
      width: '100%',
    },
    inputLabel: {
      backgroundColor: theme.palette.background.default,
      padding: '0 0.4rem',
    },
  })
);

export interface SelectOption {
  id: string;
  name: string;
}

export interface ClientSideFilterOptions {
  roles: SelectOption[];
  clients: SelectOption[];
  projects: SelectOption[];
  consultants: SelectOption[];
}

const getRoleSelectOptions = (peeps: Peep[] | undefined) => {
  if (!peeps) {
    return [];
  }

  return Array.from(new Set(peeps.map(({ positionTitle }) => positionTitle)))
    .sort()
    .map(positionTitle => ({ id: positionTitle, name: positionTitle }));
};

const getProjectSelectOptions = (peeps: Peep[] | undefined): SelectOption[] => {
  if (!peeps) {
    return [];
  }

  const projectDictionary = peeps.reduce((list, { bookings }) => {
    const projectsForPeep = bookings.reduce((list2, { project, type }) => {
      if (!isBooking(type)) return list2;
      return { ...list2, [project.id]: { id: project.id, name: project.name } };
    }, {});
    return { ...list, ...projectsForPeep };
  }, {});

  const projectArray = Object.values(projectDictionary) as SelectOption[];
  const sortedProjectArray = projectArray.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return [...sortedProjectArray, NO_PROJECTS];
};

const getClientSelectOptions = (peeps: Peep[] | undefined): SelectOption[] => {
  if (!peeps) {
    return [];
  }

  const clientDictionary = peeps.reduce((list, { bookings }) => {
    const clientsForPeep = bookings.reduce((list2, { project }) => {
      if (!project.customer) return list2;

      return {
        ...list2,
        [project.customer.id]: {
          id: project.customer.id,
          name: project.customer.name,
        },
      };
    }, {});
    return { ...list, ...clientsForPeep };
  }, {});

  const clientArray = Object.values(clientDictionary) as SelectOption[];
  const sortedClientArray = clientArray.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return sortedClientArray;
};

const getCapabilities = (peeps: Peep[] | undefined): SelectOption[] => {
  if (!peeps) {
    return [];
  }

  return Array.from(
    new Set(
      peeps.filter(x => x.department).map(({ department }) => department || '')
    )
  )
    .sort()
    .map(department => ({ id: department, name: department }));
};

const mapPeepToSelectOption = ({
  givenName,
  familyName,
  id,
}: Peep): SelectOption => ({ id, name: `${givenName} ${familyName}`.trim() });

const getPeepSelectOptions = (peep: Peep[] | undefined): SelectOption[] => {
  if (!peep) {
    return [];
  } else {
    return peep
      .map(mapPeepToSelectOption)
      .filter(({ name }) => name !== UNASSIGNED_ROLE);
  }
};

const bookingTypeOptions: SelectOption[] = [
  BookingType.Soft,
  BookingType.Unassigned,
].map(
  bookingType =>
    ({
      id: bookingType,
      name: bookingType,
    } as SelectOption)
);

const defaultFilterOptions = {
  roles: [] as SelectOption[],
  clients: [] as SelectOption[],
  projects: [] as SelectOption[],
  consultants: [] as SelectOption[],
  capabilities: [] as SelectOption[],
};

export const FilterControls: React.FC = () => {
  const classes = useStyles();
  const {
    viewMode,
    squadId,
    unfilteredPeeps,
    bookingTypes,
    searchByProject,
    searchByConsultant,
    searchByClient,
    searchByRole,
    searchByCapability,
    dispatch,
    squads,
  } = useResources();
  const [filterOptions, setFilterOptions] = useState(defaultFilterOptions);
  const [selectedConsultants, setSelectedConsultants] = useState<
    SelectOption[]
  >([]);
  const [selectedProjects, setSelectedProjects] = useState<SelectOption[]>([]);
  const [selectedClients, setSelectedClients] = useState<SelectOption[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<SelectOption[]>([]);
  const [selectedCapabilities, setSelectedCapabilities] = useState<
    SelectOption[]
  >([]);
  const [selectedBookingTypes, setSelectedBookingTypes] = useState<
    SelectOption[]
  >([]);

  const { roles, clients, consultants, projects, capabilities } = filterOptions;

  useEffect(() => {
    setFilterOptions({
      roles: getRoleSelectOptions(unfilteredPeeps),
      clients: getClientSelectOptions(unfilteredPeeps),
      consultants: getPeepSelectOptions(unfilteredPeeps),
      projects: getProjectSelectOptions(unfilteredPeeps),
      capabilities: getCapabilities(unfilteredPeeps),
    });
  }, [unfilteredPeeps]);

  useEffect(() => {
    if (
      unfilteredPeeps &&
      searchByConsultant &&
      searchByConsultant.length > 0
    ) {
      const value = unfilteredPeeps
        .filter(({ id }) => searchByConsultant.includes(id))
        .map(mapPeepToSelectOption);
      setSelectedConsultants(value);
    } else {
      setSelectedConsultants([]);
    }
  }, [unfilteredPeeps, searchByConsultant]);

  useEffect(() => {
    if (clients && searchByClient && searchByClient.length > 0) {
      const value = clients.filter(({ id }) => searchByClient.includes(id));
      setSelectedClients(value);
    } else {
      setSelectedClients([]);
    }
  }, [clients, searchByClient]);

  useEffect(() => {
    if (bookingTypes && bookingTypes.length > 0) {
      const value = bookingTypeOptions.filter(({ id }) =>
        bookingTypes.includes(id as BookingType)
      );
      setSelectedBookingTypes(value);
    } else {
      setSelectedBookingTypes([]);
    }
  }, [bookingTypes]);

  useEffect(() => {
    if (projects && searchByProject && searchByProject.length > 0) {
      const value = projects.filter(({ id }) => searchByProject.includes(id));

      setSelectedProjects(value);
    } else {
      setSelectedProjects([]);
    }
  }, [projects, searchByProject]);

  useEffect(() => {
    if (roles && searchByRole && searchByRole.length > 0) {
      const value = roles.filter(({ id }) => searchByRole.includes(id));

      setSelectedRoles(value);
    } else {
      setSelectedRoles([]);
    }
  }, [roles, searchByRole]);

  useEffect(() => {
    if (capabilities && searchByCapability && searchByCapability.length > 0) {
      const value = capabilities.filter(({ id }) =>
        searchByCapability.includes(id)
      );

      setSelectedCapabilities(value);
    } else {
      setSelectedCapabilities([]);
    }
  }, [capabilities, searchByCapability]);

  return (
    <Paper className={classes.filterContainer}>
      <Grid
        container
        direction="column"
        className={classes.gridContainer}
        justify="space-between"
      >
        <Grid item className="gridItem">
          <ButtonGroupSelect
            label="View Mode"
            value={viewMode}
            options={[ViewMode.Project, ViewMode.Booking]}
            onChange={e =>
              dispatch({
                type: FilterDispatchType.SetViewMode,
                viewMode: e as ViewMode,
              })
            }
          ></ButtonGroupSelect>

          <FormControl className={classes.filters}>
            <InputLabel className={classes.inputLabel} htmlFor="squad-select">
              Squad
            </InputLabel>
            <Select
              native
              value={squadId}
              onChange={e =>
                dispatch({
                  type: FilterDispatchType.SetSquad,
                  squadId: e.target.value as number,
                })
              }
              inputProps={{
                name: 'squad',
                id: 'squad-select',
              }}
            >
              {squads.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
          </FormControl>

          <AutocompleteMulti
            id="consultantSearch"
            label="Consultant Search"
            options={consultants}
            value={selectedConsultants}
            onChange={(_, value) => {
              dispatch({
                type: FilterDispatchType.SetSearchByConsultant,
                searchByConsultant: value.map(val => val.id) as string[],
              });
            }}
          />

          <AutocompleteMulti
            id="projectSearch"
            label="Project Search"
            options={projects}
            value={selectedProjects}
            onChange={(_, value) => {
              dispatch({
                type: FilterDispatchType.SetSearchByProject,
                searchByProject: value.map(val => val.id) as string[],
              });
            }}
          />

          <AutocompleteMulti
            id="clientSearch"
            label="Client Search"
            options={clients}
            value={selectedClients}
            onChange={(_, value) => {
              dispatch({
                type: FilterDispatchType.SetSearchByClient,
                searchByClient: value.map(val => val.id) as string[],
              });
            }}
          />

          <AutocompleteMulti
            id="capabilitySearch"
            label="Capabilities"
            options={capabilities}
            value={selectedCapabilities}
            onChange={(_, value) => {
              dispatch({
                type: FilterDispatchType.SetSearchByCapability,
                searchByCapability: value.map(val => val.id) as string[],
              });
            }}
          />

          <AutocompleteMulti
            id="roleSearch"
            label="Role Types"
            options={roles}
            value={selectedRoles}
            onChange={(_, value) => {
              dispatch({
                type: FilterDispatchType.SetRoleFilter,
                searchByRole: value.map(val => val.id) as string[],
              });
            }}
          />

          <AutocompleteMulti
            id="bookingTypeFilter"
            label="Booking Type"
            options={bookingTypeOptions}
            value={selectedBookingTypes}
            onChange={(_, value) => {
              dispatch({
                type: FilterDispatchType.SetBookingType,
                bookingTypes: value.map(val => val.id) as BookingType[],
              });
            }}
          />
        </Grid>
        <Grid item className="gridItem">
          <ClearAllButton/>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FilterControls;
