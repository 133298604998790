import { DateTime, Interval } from 'luxon';
import {
  BookingRange,
  Booking,
  BookingTypes,
  Peep,
  BookingByDay,
  GqlNonWorkingTime,
} from './models';

const weekFromIndex = (index: number): DateTime => {
  return DateTime.local()
    .plus({ weeks: index })
    .startOf('week');
};

const isInternalBooking = (client: string) => {
  return client === 'Telstra Internal' || client === 'Telstra Purple Pty Ltd';
};

const getBookingType = (type: string, client: string) => {
  switch (type) {
    case 'Leave':
      return BookingTypes.Leave;
    case BookingTypes.PublicHoliday:
      return BookingTypes.PublicHoliday;
    case BookingTypes.PlannedPD:
      return BookingTypes.PlannedPD;
    case 'Hard':
      return isInternalBooking(client)
        ? BookingTypes.TelstraInternalHard
        : BookingTypes.HardBooking;
    case 'Soft':
      return isInternalBooking(client)
        ? BookingTypes.TelstraInternalSoft
        : BookingTypes.SoftBooking;
    default:
      return BookingTypes.General;
  }
};

export class PeepRow {
  constructor(
    public id: string = '',
    public givenName: string = '',
    public familyName: string = '',
    public positionTitle: string = '',
    public weeks: Week[] = [],
    public bookings: Booking[] = [],
    public overlappingBookings: number = 0,
    public interSquad?: string,
    public department?: string
  ) {}
}

export interface PeepTable {
  data: PeepRow[] | undefined;
  rows: number;
  columns: number;
}

export interface Week {
  weekStart: DateTime;
  bookingRanges: BookingRange[];
  overlaps: number;
}

const previousWasYesterday = (
  previousBooking: BookingByDay | undefined,
  todayBooking: BookingByDay | undefined
) => {
  if (previousBooking && todayBooking) {
    const interval = Interval.fromISO(
      `${previousBooking.date}/${todayBooking.date}`
    );
    return interval.count('day') === 2;
  } else {
    return false;
  }
};

const bookingsToRanges = (
  bookings: Booking[],
  weekStart: DateTime,
  endOfWeek: DateTime,
  projectGrouping: string | undefined
): BookingRange[] => {
  return bookings.reduce(
    (ranges: BookingRange[], booking: Booking): BookingRange[] => {
      const days = booking.bookingsByDay
        .filter(({ date: dateString }) => {
          const date = DateTime.fromISO(dateString);
          if (!date || date < weekStart || date > endOfWeek) {
            return false;
          }
          return true;
        })
        .sort();

      if (days.length === 0) {
        return ranges;
      }

      let previous = undefined as BookingByDay | undefined;
      days.forEach(today => {
        if (previousWasYesterday(previous, today)) {
          const { hours, ...rest } = ranges[ranges.length - 1];

          ranges[ranges.length - 1] = {
            ...rest,
            hours: [...hours, today.hours],
            endDay: DateTime.fromISO(today.date).weekday,
          };
        } else if (previous && previous.date === today.date) {
          const hours = ranges[ranges.length - 1].hours;
          if (hours && hours.length > 1) {
            hours[hours.length - 1] = +today.hours;
          }
        } else {
          const client = booking?.project?.customer?.name;
          const date = DateTime.fromISO(today.date);
          const bookingType = getBookingType(booking.type, client || '');
          const getHoursRemaining = () =>
            calculateRemainingHours(bookings, booking.project.id, date);

          const emphasise = projectGrouping
            ? projectGrouping === booking.project.name
            : true;

          const range: BookingRange = {
            key: `${booking.project.name} ${date.toFormat('MM-dd')} ${
              booking.bookingsByDay[0]?.id
            } ${booking.source}`,
            startDay: date.weekday,
            endDay: date.weekday,
            project: booking.project.name,
            projectId: booking.project.id,
            source: booking.source,
            salesRepresentative: booking.project.salesRepresentative?.name,
            client,
            bookingType,
            hours: [today.hours],
            emphasise,
            getHoursRemaining,
          };
          const isDuplicate = ranges.some(r => r.key === range.key);
          if (!isDuplicate) {
            ranges.push(range);
          }
        }
        previous = today;
      });

      return ranges;
    },
    [] as BookingRange[]
  );
};
const calculateMaxOverlapsForWeek = (ranges: BookingRange[]) => {
  const overlaps = ranges.reduce(
    (overlaps, range) => {
      const { hours, startDay } = range;
      hours.forEach((_value, index) => {
        overlaps[index + startDay]++;
      });
      return overlaps;
    },
    [0, 0, 0, 0, 0, 0] as number[]
  );

  return overlaps.reduce((a, b) => (a > b ? a : b), 0);
};
const bookingsToWeek = (
  bookings: Booking[],
  projectGrouping?: string | undefined
): Week[] => {
  const weeks = [] as Week[];

  for (let i = 0; i < 12; i++) {
    const weekStart = weekFromIndex(i);
    const endOfWeek = weekFromIndex(i).endOf('week');
    const bookingRanges = bookingsToRanges(
      bookings,
      weekStart,
      endOfWeek,
      projectGrouping
    );
    const overlaps = calculateMaxOverlapsForWeek(bookingRanges);
    weeks.push({
      weekStart,
      bookingRanges,
      overlaps,
    });
  }

  return weeks;
};

export const peepsToPeepRows = (
  peeps: Peep[] | undefined,
  projectGrouping?: string | undefined
): PeepRow[] | undefined => {
  if (!peeps) {
    return undefined;
  }
  return peeps.map(
    (peep): PeepRow => {
      const weeks = bookingsToWeek(peep.bookings, projectGrouping);
      const overlappingBookings = weeks.reduce(
        (max, { overlaps }) => (max > overlaps ? max : overlaps),
        0
      );
      return {
        ...peep,
        weeks: bookingsToWeek(peep.bookings, projectGrouping),
        overlappingBookings,
      };
    }
  );
};

export const UNASSIGNED_ROLE = 'Unassigned';

export const transformBookingsToPeeps = (gqlResponse): Peep[] | undefined => {
  if (!gqlResponse) {
    return undefined;
  }

  const result: Record<string, Peep> = {};
  const placeholderResults: Peep[] = [];

  gqlResponse.bookings.forEach(booking => {
    const peep = booking.against;
    if (!('id' in peep)) {
      const existingPlaceholder = placeholderResults.find(
        ph =>
          ph.positionTitle === peep.roleName &&
          ph.bookings.some(b => b.project.id === booking.project.id)
      );
      if (existingPlaceholder) {
        existingPlaceholder.bookings.push({
          type: booking.type,
          project: booking.project,
          bookingsByDay: booking.bookingsByDay,
          startDate: booking.startDate,
          endDate: booking.endDate,
        });
      } else {
        placeholderResults.push({
          id: `placeholder-${placeholderResults.length + 1}`,
          givenName: UNASSIGNED_ROLE,
          familyName: '',
          positionTitle: peep.roleName,
          bookings: [
            {
              type: booking.type,
              project: booking.project,
              bookingsByDay: booking.bookingsByDay,
              startDate: booking.startDate,
              endDate: booking.endDate,
            },
          ],
        });
      }
    } else if (peep.id in result) {
      result[peep.id].bookings.push({
        type: booking.type,
        project: booking.project,
        bookingsByDay: booking.bookingsByDay,
        startDate: booking.startDate,
        endDate: booking.endDate,
      });
    } else {
      result[peep.id] = {
        id: peep.id,
        givenName: peep.givenName,
        familyName: peep.familyName,
        positionTitle: peep.positionTitle ?? 'NonPurplePerson',
        department: peep.department,
        interSquad:
          peep.squad?.id !== gqlResponse.id ? peep.squad?.name : undefined,
        bookings: [
          {
            type: booking.type,
            project: booking.project,
            bookingsByDay: booking.bookingsByDay,
            startDate: booking.startDate,
            endDate: booking.endDate,
          },
        ],
      };
    }
  });

  const buildBookings = (
    peeps: Record<string, Peep>,
    id: string,
    days: GqlNonWorkingTime[],
    type: BookingTypes
  ) => {
    days
      .filter(day => day.status !== 'Cancelled' && day.hours > 0)
      .forEach(day => {
        const startDate = DateTime.fromISO(day.startDate);
        const endDate = DateTime.fromISO(day.endDate);
        const dates = Array.from(
          Array(endDate.diff(startDate, 'days').days + 1).keys()
        )
          .map(n => startDate.plus({ days: n }))
          .filter(d => d.weekday < 6)
          .map(n => n.toISODate());

        peeps[id].bookings.push({
          type: type,
          project: { name: day.type, id: type },
          source: day.source,
          startDate: day.startDate,
          endDate: day.endDate,
          bookingsByDay: dates.map(date => ({
            date: date,
            hours: day.hours > 8 ? 8 : day.hours,
            id: `${id}-${date}-${type}`,
          })),
        });
      });
  };

  gqlResponse.people.forEach(peep => {
    if (!(peep.id in result)) {
      result[peep.id] = {
        id: peep.id,
        givenName: peep.givenName,
        familyName: peep.familyName,
        positionTitle: peep.positionTitle,
        department: peep.department,
        bookings: [],
      };
    }

    peep.publicHolidays.forEach(day => {
      if (DateTime.fromFormat(day.startDate, 'yyyy-MM-dd').weekday <= 5) {
        result[peep.id].bookings.push({
          type: BookingTypes.PublicHoliday,
          project: { name: day.name, id: 'Leave' },
          startDate: day.startDate,
          endDate: day.endDate,
          bookingsByDay: [
            {
              date: day.startDate,
              hours: day.hours || 8,
              id: `${peep.id}-${day.startDate}-PublicHoliday`,
            },
          ],
        });
      }
    });

    buildBookings(
      result,
      peep.id,
      peep.professionalDevelopment,
      BookingTypes.PlannedPD
    );
    buildBookings(result, peep.id, peep.leave, BookingTypes.Leave);
  });

  return Object.values(result)
    .sort((a, b) => a.givenName.localeCompare(b.givenName))
    .concat(placeholderResults);
};

export const calculateRemainingHours = (
  allBookings: Booking[],
  projectId: string,
  minDate: DateTime
) => {
  const allBookingsForProject = allBookings.filter(
    b => b.project.id === projectId
  );

  const totalHours = allBookingsForProject.reduce(
    (accHoursForProject, booking) =>
      booking.bookingsByDay
        .filter(b => DateTime.fromISO(b.date) >= minDate)
        .reduce(
          (accHoursForBookingBlock, bookingByDay) =>
            bookingByDay.hours + accHoursForBookingBlock,
          0
        ) + accHoursForProject,
    0
  );

  return Math.round(totalHours * 100) / 100;
};
