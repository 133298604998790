import React, { useCallback, useEffect, useState } from 'react';
import {
  makeStyles,
  createStyles,
  TextField,
  Typography,
  Paper,
  Theme,
  lighten,
} from '@material-ui/core';
import { useResources } from './Resources';
import debounce from 'lodash/debounce';
import { FilterDispatchType } from '../hooks/useFilterReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchContainer: {
      width: '100%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        marginRight: '8px',
      },
    },
    textSearch: {
      width: '100%',
      borderRadius: '3px',
    },
    input: {
      padding: '23px 12px 3px 12px',
      backgroundColor: '#fff',
      color: 'black',
    },
    inputLabel: {
      color: lighten(theme.palette.getContrastText('#fff'), 0.25),
    },
    paper: {
      right: '8.7rem',
      top: '4rem',
      display: 'block',
      position: 'fixed',
      width: '15rem',
      padding: '0.3rem 1.3rem',
      zIndex: 1000,
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 3rem)',
      [theme.breakpoints.down('sm')]: {
        right: '8.25rem',
      },
    },
  })
);

const FreeTextSearch: React.FC = () => {
  const classes = useStyles();
  const { freeTextSearch, dispatch } = useResources();
  const [localFreeTextSearch, setLocalFreeTextSearch] = useState(
    freeTextSearch
  );

  useEffect(() => {
    setLocalFreeTextSearch(freeTextSearch);
  }, [freeTextSearch]);

  const updateFreeText = (value: string) => {
    dispatch({
      type: FilterDispatchType.SetFreeTextSearch,
      freeTextSearch: value,
    });
  };
  const debounceFreeTextSearch = useCallback(debounce(updateFreeText, 500), [
    dispatch,
  ]);

  const [infoVisible, setInfoVisible] = useState<boolean>(false);

  return (
    <div
      className={classes.searchContainer}
      onMouseEnter={() => setInfoVisible(true)}
      onMouseLeave={() => setInfoVisible(false)}
    >
      <TextField
        className={classes.textSearch}
        id="freeTextSearch"
        label="Search"
        value={localFreeTextSearch}
        onChange={x => {
          setLocalFreeTextSearch(x.target.value);
          debounceFreeTextSearch(x.target.value);
        }}
        variant="filled"
        inputProps={{
          className: classes.input,
          autoComplete: 'off',
        }}
        InputLabelProps={{
          className: classes.inputLabel
        }}
      />
      {infoVisible && (
        <Paper className={classes.paper}>
          <Typography variant="body2">
            <b>
              You can also use prefixes to refine your search. To combine
              categories use a space and to include multiple values use comma
              separations instead.
            </b>
            <i>
              <br /> e.g. co:name1,name2 r:consultant
            </i>
            <br />
            <br />
            co: {'<consultant name>'} <br />
            r: {'<role name>'}
            <br />
            pr: {'<project name>'}
            <br />
            cl: {'<client name>'}
            <br />
            s: {'<sales rep name>'}
            <br />
            is: {'<inter-squad>'}
            <br />
            ca: {'<capability>'}
          </Typography>
        </Paper>
      )}
    </div>
  );
};

export default FreeTextSearch;
