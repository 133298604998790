import React, { useState } from 'react';
import {
  makeStyles,
  createStyles,
  CssBaseline,
  Grid,
  CircularProgress,
  ThemeProvider,
  ThemeOptions,
  createMuiTheme,
  Theme,
} from '@material-ui/core';
import { ComponentsProps } from '@material-ui/core/styles/props';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { ApolloProvider } from '@apollo/client';
import { ReactComponent as Logo } from './assets/pedro-nametag.svg';
import ErrorBoundary from './components/ErrorBoundary';
import Resources from './components/Resources';
import Main from './components/Main';
import { useAccount } from './services/auth';
import { purpleGraphClient } from './services/purpleGraph';
import Header from './components/Header';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      width: '100vw',
      position: 'fixed',
    },
    logo: {
      fill: '#000',
      top: 'calc(50% - 75px)',
      left: 'calc(50% - 75px)',
      width: '150px',
      height: '150px',
      position: 'absolute',
    },
    progress: {
      top: 'calc(50% - 128px)',
      left: 'calc(50% - 128px)',
      position: 'absolute',
      color: '#000',
      strokeLinecap: 'round',
    },
    grid: {
      height: '100%',
    },
    loadingScreen: {
      width: '100%',
      height: '100%',
    },
    main: {
      background: theme.palette.background.default,
      flexGrow: 1,
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      position: 'relative',
    },
  })
);

const BigSpinner: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.loadingScreen}>
      <CircularProgress className={classes.progress} size={256} thickness={2} />
      <Logo className={classes.logo} />
    </div>
  );
};

export const AuthContext = React.createContext<any | undefined>(undefined);

const PureApp: React.FC<{ onThemeChanged: (themeName: string) => void }> = ({
  onThemeChanged,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        wrap="nowrap"
        className={classes.grid}
      >
        <Resources>
          <Header onThemeChanged={onThemeChanged} />
          <main className={classes.main}>
            <ErrorBoundary large>
              <Main />
            </ErrorBoundary>
          </main>
        </Resources>
      </Grid>
    </div>
  );
};

const commonThemeProps: ComponentsProps = {
  MuiFormControl: {
    margin: 'dense',
    variant: 'outlined',
  },
  MuiTextField: {
    margin: 'dense',
    variant: 'outlined',
  },
  MuiButton: {
    variant: 'outlined',
  },
};

const lightThemeProps: ThemeOptions = {
  props: commonThemeProps,
  palette: {
    type: 'light',
  },
};

const darkThemeProps: ThemeOptions = {
  props: commonThemeProps,
  palette: {
    type: 'dark',
    primary: {
      main: '#99F'
    }
  },
};

const App = () => {
  const account = useAccount();
  const [isDarkMode, setIsDarkMode] = useState(false);
  return (
    <AuthContext.Provider value={account}>
      <ApolloProvider client={purpleGraphClient}>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <ThemeProvider
            theme={
              isDarkMode
                ? createMuiTheme(darkThemeProps)
                : createMuiTheme(lightThemeProps)
            }
          >
            <CssBaseline />
            {account && (
              <PureApp
                onThemeChanged={themeName =>
                  setIsDarkMode(themeName === 'dark')
                }
              />
            )}
            {!account && <BigSpinner />}
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </ApolloProvider>
    </AuthContext.Provider>
  );
};

export default App;
