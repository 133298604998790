import {
  AccountInfo,
  Configuration,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import { useEffect, useState } from 'react';
import { scopes as purpleGraphScopes } from './purpleGraph';

export const oidcScopes = ['openid', 'offline_access', 'profile'];

const config: Configuration = {
  auth: {
    clientId:
      process.env.REACT_APP_CLIENT_ID || 'aaa72990-515b-42b1-bda8-2e5fb9a5bc87',
    authority:
      process.env.REACT_APP_AUTHORITTY ||
      'https://login.microsoftonline.com/fc6a7adc-53de-44ad-88fc-af783bbb1d6e',
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
};

export const msalApp = new PublicClientApplication(config);

let accountInfo: AccountInfo | null;
let loginCallback: (account: AccountInfo) => void;

msalApp.handleRedirectPromise().then(authenticationResult => {
  if (authenticationResult) {
    accountInfo = authenticationResult?.account;
  } else {
    const accounts = msalApp.getAllAccounts();
    if (!accounts)
      return msalApp.loginRedirect({
        scopes: oidcScopes.concat(purpleGraphScopes),
      });
    else accountInfo = accounts[0]; // TODO ummmm select your account ?
  }
  if (loginCallback) loginCallback(accountInfo);
});

const login = (callback: (account: AccountInfo) => void) => {
  if (accountInfo) return callback(accountInfo);
  loginCallback = callback;
};

export function useAccount() {
  const [account, setAccount] = useState<AccountInfo | undefined>(undefined);
  useEffect(() => {
    function onLogin(userAccountInfo: AccountInfo) {
      setAccount(userAccountInfo);
    }
    login(onLogin);
  }, []);
  return account;
}

export const acquireToken = async (scopes: string[]) => {
  if (!accountInfo) throw new Error('not logged in');
  return msalApp
    .acquireTokenSilent({ scopes, account: accountInfo })
    .catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        return msalApp.acquireTokenRedirect({
          ...{ scopes, account: accountInfo! },
        });
      } else {
        throw error;
      }
    });
};
