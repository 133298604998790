import React, { useState, useEffect, useCallback } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import BrightnessMediumIcon from '@material-ui/icons/BrightnessMedium';
import { ReactComponent as Logo } from '../assets/pedro-nametag.svg';
import FilterPanelManager from './FilterPanelManager';
import InformationDialog from './InformationDialog';
import LastUpdatedPopUp from './LastUpdatedPopUp';
import FreeTextSearch from './FreeTextSearch';
import { cacheKeys, getFromCache, saveToCache } from '../services/cacheService';

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      display: 'flex',
      backgroundColor: '#000',
      color: '#fff',
      '& > div': {
        height: '4rem',
        minHeight: '4rem',
      },
    },
    installButton: {
      margin: '0 1rem',
    },
    logo: {
      width: '2.5rem',
      height: '2.5rem',
      marginRight: '2rem',
      fill: '#fff',
      [theme.breakpoints.down('sm')]: {
        marginRight: '8px',
      },
    },
    userName: {
      margin: '0 1rem',
    },
    toolbar: {
      justifyContent: 'space-between',
    },
    start: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    end: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
    title: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    themeButton: {
      color: '#fff !important',
      borderColor: '#fff !important',
    },
  })
);

const Header: React.FC<{ onThemeChanged: (themeName: string) => void }> = ({
  onThemeChanged,
}) => {
  const classes = useStyles();
  const [installEvent, setInstallEvent] = useState<any | undefined>(undefined);
  const [isDarkMode, setIsDarkMode] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const installListener = e => {
      setInstallEvent(e);
      e.preventDefault();
    };
    window.addEventListener('beforeinstallprompt', installListener, true);
    return () => {
      window.removeEventListener('beforeinstallprompt', installListener, true);
    };
  });

  const setDarkMode = useCallback((darkMode: boolean) => {
    setIsDarkMode(darkMode);
    onThemeChanged(darkMode ? 'dark' : 'light');
  }, [onThemeChanged]);

  useEffect(() => {
    if (isDarkMode !== undefined) {
      saveToCache(cacheKeys.isDarkMode, isDarkMode);
      setDarkMode(isDarkMode);
    } else {
      const isOriginallyDarkMode = getFromCache<boolean>(cacheKeys.isDarkMode);
      setDarkMode(isOriginallyDarkMode ?? false);
    }
  }, [isDarkMode, setDarkMode]);

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.start}>
          <Logo className={classes.logo} />
          <Typography className={classes.title} variant="h6">
            Pedro
          </Typography>

          {!!installEvent && (
            <Button
              className={classes.installButton}
              variant="contained"
              onClick={() => {
                installEvent.prompt();
                installEvent.userChoice.then(choiceResult => {
                  if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                  } else {
                    console.log('User dismissed the A2HS prompt');
                  }
                  setInstallEvent(undefined);
                });
              }}
            >
              Install
            </Button>
          )}
        </div>
        <div className={classes.end}>
          <FreeTextSearch />
          <Button
            className={classes.themeButton}
            onClick={() => setIsDarkMode(!isDarkMode)}
            aria-label="Swap themes"
          >
            <BrightnessMediumIcon />
          </Button>
          <LastUpdatedPopUp />
          <InformationDialog />
          <FilterPanelManager />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
