import React, { useState, createContext, useContext, useEffect } from 'react';
import { Squad, Peep } from '../services/models';
import { useQuery, useLazyQuery } from '@apollo/client';
import { BOOKING_QUERY, SQUAD_LIST_QUERY } from '../services/queries';
import useFilterReducer, {
  FilterState,
  FilterDispatch,
  FilterDispatchType,
  BookingType,
  ViewMode,
} from '../hooks/useFilterReducer';
import { loadFilterState } from '../services/filterStateService';
import { default as peepData } from '../services/peepData.json';
import { default as squadListData } from '../services/squadListData.json';
import {
  transformBookingsToPeeps,
  UNASSIGNED_ROLE,
  peepsToPeepRows,
  PeepRow,
} from '../services/mapper';
import { DateTime } from 'luxon';
import { COLUMNS } from './Grid/GridDefaults';
import { NO_PROJECTS } from '../services/projectService';
import { getOrSetFromCache, cacheKeys } from '../services/cacheService';
import getPeepsFilteredByText from '../services/textFilterService';

const DEV_DATA = process.env.REACT_APP_DEV_DATA === 'true';

export interface PeepOption {
  id: string;
  name: string;
}

export interface ClientSideFilterOptions {
  roles: string[];
  clients: string[];
  projects: string[];
  consultants: PeepOption[];
}

export type ResourcesContextValue = {
  squads: Squad[];
  unfilteredPeeps: Peep[] | undefined;
  filteredPeeps: Peep[] | undefined;
  lastUpdate: number | undefined;
  filteredPeepRows: PeepRow[] | undefined;
  isLoadingData: boolean;
  hasErrors: boolean;
  dispatch: FilterDispatch;
} & FilterState;

const initialFilters = loadFilterState();

const ResourcesContext = createContext<ResourcesContextValue>({
  squads: [] as Squad[],
  filteredPeeps: undefined,
  unfilteredPeeps: [] as Peep[],
  lastUpdate: undefined,
  filteredPeepRows: undefined,
  isLoadingData: false,
  hasErrors: false,
  dispatch: () => undefined,
  ...initialFilters,
});

export interface PeepFilterOptions {
  searchByClient: string[];
  searchByProject: string[];
  searchByConsultant: string[];
  bookingTypes: BookingType[];
  searchByRole: string[];
  searchByCapability: string[];
  freeTextSearch: string;
}

export const isUnassignedOnDay = (peep: Peep, today: string) => {
  const isAssigned = peep.bookings.some(booking =>
    booking.bookingsByDay.some(b => b.date === today)
  );
  const isPeep = peep.givenName !== UNASSIGNED_ROLE;

  return !isAssigned && isPeep;
};

export const filterPeeps = (
  peeps: Peep[] | undefined,
  {
    searchByClient,
    searchByProject,
    searchByConsultant,
    bookingTypes,
    searchByRole,
    searchByCapability,
    freeTextSearch,
  }: PeepFilterOptions
) => {
  if (!peeps) {
    return undefined;
  }

  if (
    searchByClient.length === 0 &&
    searchByConsultant.length === 0 &&
    searchByProject.length === 0 &&
    searchByRole.length === 0 &&
    searchByCapability.length === 0 &&
    bookingTypes.length === 0 &&
    !freeTextSearch
  ) {
    return peeps;
  }

  const today = DateTime.local().toFormat('yyyy-MM-dd');

  const result = peeps.filter(peep => {
    const matchesClient = peep.bookings.some(
      ({ project }) =>
        project.customer?.id && searchByClient.includes(project.customer?.id)
    );
    const matchesProject = peep.bookings.some(({ project }) =>
      searchByProject.includes(project.id)
    );
    const matchesNoProject =
      searchByProject.includes(NO_PROJECTS.id) &&
      isUnassignedOnDay(peep, today);
    const matchesConsultant = searchByConsultant.includes(peep.id);
    const matchesRole = searchByRole.includes(peep.positionTitle);

    const matchesSoftBooking =
      bookingTypes.includes(BookingType.Soft) &&
      peep.givenName !== UNASSIGNED_ROLE &&
      peep.bookings.some(booking => booking.type === BookingType.Soft);

    const matchesUnassignedBooking =
      bookingTypes.includes(BookingType.Unassigned) &&
      peep.givenName === UNASSIGNED_ROLE;

    const matchesCapability =
      peep.department && searchByCapability.includes(peep.department);

    const trimmedFreeTextSearch = freeTextSearch?.trim();
    const matchesFreeText = trimmedFreeTextSearch && getPeepsFilteredByText(trimmedFreeTextSearch, [peep]).length > 0;

    return (
      matchesClient ||
      matchesProject ||
      matchesNoProject ||
      matchesConsultant ||
      matchesRole ||
      matchesCapability ||
      matchesSoftBooking ||
      matchesUnassignedBooking ||
      matchesFreeText
    );
  });

  return result;
};

const Resources: React.FC = ({ children }) => {
  const [unfilteredPeeps, setUnfilteredPeeps] = useState<Peep[] | undefined>();
  const [lastUpdate, setLastUpdate] = useState<number | undefined>();

  const [filteredPeeps, setFilteredPeeps] = useState<Peep[] | undefined>();

  const [filteredPeepRows, setFilteredPeepRows] = useState<
    PeepRow[] | undefined
  >();
  const [squads, setSquads] = useState([] as Squad[]);
  const [filters, dispatch] = useFilterReducer(loadFilterState());

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [
    getBookings,
    { data, loading: loadingBookings, error: dataErrors },
  ] = useLazyQuery(BOOKING_QUERY);
  const { data: squadData, loading: loadingSquads } = useQuery(
    SQUAD_LIST_QUERY,
    {
      skip: DEV_DATA,
    }
  );

  useEffect(() => {
    setIsLoadingData(loadingBookings || loadingSquads);
    changeLastUpdated(data);
    setHasErrors(!!dataErrors);
  }, [loadingBookings, loadingSquads, data, dataErrors]);

  const {
    searchByRole,
    searchByProject,
    searchByConsultant,
    searchByClient,
    searchByCapability,
    bookingTypes,
    freeTextSearch,
    viewMode,
    ...serverSideFilters
  } = filters;

  const { squadId } = serverSideFilters;



  useEffect(() => {
    if (!DEV_DATA) {
      dispatch({
        type: FilterDispatchType.SetRoleFilter,
        searchByRole: [],
      });

      const today = DateTime.local();
      const defaultStartDate = today.startOf('week').toFormat('yyyy-MM-dd');
      const defaultEndDate = today
        .endOf('week')
        .plus({ weeks: COLUMNS })
        .toFormat('yyyy-MM-dd');

      getBookings({
        variables: {
          squadId,
          startDate: defaultStartDate,
          endDate: defaultEndDate,
        },
      });
    }
  }, [squadId, getBookings, dispatch]);

  useEffect(() => {
    const graphQLResponse = (DEV_DATA ? peepData : data)?.squad;
    const peeps = transformBookingsToPeeps(graphQLResponse);
    const peepsCache = getOrSetFromCache(cacheKeys.peeps, peeps);
    setUnfilteredPeeps(peepsCache);

    const filteredGqlPeeps = filterPeeps(peepsCache, {
      searchByClient,
      searchByProject,
      bookingTypes,
      searchByRole,
      searchByConsultant,
      searchByCapability,
      freeTextSearch,
    });

    setFilteredPeeps(filteredGqlPeeps);
    if (viewMode === ViewMode.Booking && !isLoadingData) {
      setFilteredPeepRows(peepsToPeepRows(filteredGqlPeeps));
    }
  }, [
    viewMode,
    searchByRole,
    bookingTypes,
    data,
    searchByProject,
    searchByClient,
    searchByConsultant,
    searchByCapability,
    freeTextSearch,
    squadId,
    isLoadingData
  ]);

  const [hasErrors, setHasErrors] = useState<boolean>(false);

  useEffect(() => {
    const response = DEV_DATA ? squadListData : squadData;
    const squadListResponse = response?.squads;
    const squadList = getOrSetFromCache(cacheKeys.squads, squadListResponse);
    setSquads(squadList ? [...squadList] : []);
  }, [squadData]);

  const changeLastUpdated = (response: any) => {
    let lastUpdatedCache;
    if (response) {
      lastUpdatedCache = getOrSetFromCache(
        cacheKeys.lastUpdatedDate,
        Date.now()
      );
    } else {
      lastUpdatedCache = getOrSetFromCache(
        cacheKeys.lastUpdatedDate,
        undefined
      );
    }
    setLastUpdate(lastUpdatedCache);
  };

  return (
    <ResourcesContext.Provider
      value={{
        squads,
        unfilteredPeeps,
        filteredPeeps,
        lastUpdate,
        filteredPeepRows,
        isLoadingData,
        hasErrors,
        ...filters,
        dispatch,
      }}
    >
      {children}
    </ResourcesContext.Provider>
  );
};

export default Resources;

export const useResources = () => useContext(ResourcesContext);
