import React from 'react';
import BookingView from './Booking/BookingView';
import ProjectsView from './Project/ProjectView';
import { useResources } from './Resources';
import { ViewMode } from '../hooks/useFilterReducer';

const Main = () => {
  const { viewMode } = useResources();
  return viewMode === ViewMode.Booking ? <BookingView /> : <ProjectsView />;
};

export default Main;
