import React, { memo, useEffect, useState } from 'react';
import { orderBookingsByProject } from '../../services/projectService';
import ProjectViewCell from './ProjectViewCell';
import { useResources } from '../Resources';
import AutosizedVirtualGrid from '../Grid/AutosizedVirtualGrid';
import { ROW_HEIGHT, ROW_PADDING } from '../Grid/GridDefaults';
import ColumnHeaders from './ColumnHeaders';
import { PeepRow } from '../../services/mapper';
import { PeepProject } from '../../services/models';


const ProjectsView = () => {
  const {
    filteredPeeps,
    searchByRole,
    searchByProject,
    searchByConsultant,
    searchByClient,
    searchByCapability,
    bookingTypes,
    lastUpdate,
    isLoadingData,
  } = useResources();
  const [processedData, setProcessedData] = useState<(PeepProject | PeepRow)[] | undefined>();
  useEffect(() => {

  if(!isLoadingData) {
    setProcessedData(orderBookingsByProject(filteredPeeps, {
      searchByRole,
      searchByProject,
      searchByConsultant,
      searchByClient,
      searchByCapability,
      bookingTypes}));
  }
  },[
    filteredPeeps,
    searchByRole,
    searchByProject,
    searchByConsultant,
    searchByClient,
    searchByCapability,
    bookingTypes,
    isLoadingData
  ])

  const CellMemo = memo(
    ProjectViewCell,
    (oldProps, { columnIndex, rowIndex, isScrolling }) =>
      oldProps.columnIndex === columnIndex &&
      oldProps.rowIndex === rowIndex &&
      isScrolling
  );

  const rowHeight = r => {
    if (!processedData) {
      return ROW_HEIGHT;
    }
    const row = processedData[r];
    if ('overlappingBookings' in row) {
      const overlap = row.overlappingBookings > 0 ? row.overlappingBookings : 1;

      return overlap * ROW_HEIGHT + ROW_PADDING * 2;
    } else {
      return ROW_HEIGHT;
    }
  };

  return (
    <AutosizedVirtualGrid
      data={processedData}
      lastUpdate={lastUpdate}
      rowHeight={rowHeight}
      Cell={CellMemo}
      StickyColumn={ColumnHeaders}
      isLoadingData={isLoadingData}
    />
  );
};

export default ProjectsView;
