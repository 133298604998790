import { makeStyles, createStyles, Button } from "@material-ui/core";
import React from "react";
import { FilterDispatchType } from "../hooks/useFilterReducer";
import { useResources } from "./Resources";

const useStyles = makeStyles(() =>
  createStyles({
    clearAll: {
        width: '100%',
        marginTop: '1rem', // sets itself apart a bit
        marginBottom: '0.5rem',
      },
  })
);

const ClearAllButton: React.FC<{text?: string}> = ({text}) => {
  const classes = useStyles();
const {dispatch} = useResources();

  return (
    <Button
    className={classes.clearAll}
    onClick={() => dispatch({ type: FilterDispatchType.Reset })}
  >
    {text ? text :'Clear All'}
  </Button>
  );
};

export default ClearAllButton;