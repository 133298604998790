import React, { useState } from 'react';
import FilterControls from './FilterControls';
import TuneIcon from '@material-ui/icons/Tune';
import { makeStyles, createStyles } from '@material-ui/core';

/**
 * Q. Why is this statically positioned?
 * A. Our table headers are virtual, using a specialised table component.
 *    If we want content to be at the far right of the table view, we can't actually put it in the header,
 *    it needs to be statically positioned to appear as though it's in the header.
 */

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      marginLeft: '10px',
      fontSize: '2rem',
      cursor: 'pointer',
    },
  })
);

const FilterPanelManager: React.FC = () => {
  const [controlsVisible, setControlsVisible] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <>
      <div>
        <TuneIcon
          fontSize="large"
          className={classes.icon}
          onClick={() => setControlsVisible(!controlsVisible)}
        />
      </div>
      {controlsVisible && <FilterControls />}
    </>
  );
};

export default FilterPanelManager;
